/**
 * Just view/template component, no model/data logic!
 */
import React from "react"

// s4n
import kebabCase from "lodash.kebabcase"
// import "./../../assets/sass/templates/node/_blog.scss"
import BlogCard from './../Blog/card'



const ComponentStateless = (props) => {
  const { data, intl, translate: t } = props; // alias t === translate function

  const linkMap = {
    pl: {
      blog: `Blog`,
      news: `Aktualnosci`,
    },
    en: {
      blog: `Blog`,
      news: `News`,
    }
  }

  const linkTranslation = kebabCase(linkMap[intl.locale][data.pageContext.field_type]);

  return (
    <React.Fragment>
      <section className="section">
        <div className="container">
          <div className="row items">

            {/* @see: https://hackernoon.com/5-techniques-to-iterate-over-javascript-object-entries-and-their-performance-6602dcb708a8 */}
            {data.aDataCollectionTranslated.map(({ node }) => {
              return <BlogCard key={`blog-item-${node.id}`} node={node} linkTranslation={linkTranslation} />
            })}

          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ComponentStateless