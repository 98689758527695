import React from "react"
import Layout from "./../../layout"
import SEO from "./../../seo"

// s4n
// import "./../assets/sass/pages/_blog.scss"
import { useIntl } from "gatsby-plugin-intl"
// import Tags from "./tags"
import BlogIndexTemplate from "./../templates/blog-index"
import Pagination from './../Pagination'
import kebabCase from "lodash.kebabcase"



const ComponentStateless = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const { propsData } = props;

  const { pageContext, aDataCollectionTranslated } = propsData;

  // console.log(propsData)

  const linkMap = {
    pl: {
      blog: `Blog`,
      news: `Aktualności`,
    },
    en: {
      blog: `Blog`,
      news: `News`,
    }
  }

  const nodeInfoForLayoutComponent = {
    title: linkMap[intl.locale][pageContext.field_type],
    internal: {
      type: ``,
    },
    drupal_internal__nid: process.env.SOFT4NET_BLOG_PAGE_DRUPAL_ID,
    drupal_internal__product_id: null,
  }

  const linkTranslation = kebabCase(linkMap[intl.locale][pageContext.field_type]);

  return (
    <Layout 
      node={nodeInfoForLayoutComponent}
      className="s4n-blog-index"
    >
        <SEO title={linkMap[intl.locale][pageContext.field_type]} keywords={``} nodeId={process.env.SOFT4NET_BLOG_PAGE_DRUPAL_ID} />

        {/* <div className="container">
          <h1>{ linkMap[intl.locale][pageContext.field_type] }</h1>
        </div> */}
        <section className="page-title-alt _bg-primary position-relative">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="text-white font-tertiary">{ linkMap[intl.locale][pageContext.field_type] }</h1>
              </div>
            </div>
          </div>
        </section>

        {/* <Tags pageContext={pageContext} linkTranslation={linkTranslation} /> */}
        <BlogIndexTemplate data={propsData} intl={intl} translate={intl.formatMessage} />
        <Pagination pageContext={pageContext} linkTranslation={linkTranslation} />
    </Layout>
  )
}

export default ComponentStateless