import React from "react"
import { graphql } from "gatsby"

// s4n
import FragmentBlogIndex from '../../model/fragments/allNodeBlog/blog-index'
import { useIntl } from "gatsby-plugin-intl"
import CollectionDataUtils from "./../../utils/Collection/data"
import BlogIndex from './../../components/s4n/Blog'



const ComponentStateless = (props) => {
    const intl = useIntl();

    const { data: { index }, pageContext } = props
    const aDataCollectionTranslated = CollectionDataUtils.filterData(index.edges, intl.locale);
    const propsData = {
        pageContext,
        // aDataCollectionTranslated: index.edges
        aDataCollectionTranslated: aDataCollectionTranslated,
    };

    // console.log(pageContext)
    // console.log(index.edges)

    return <BlogIndex propsData={propsData} />
}

export default ComponentStateless

export const query = graphql`
    query (
        $skip: Int!, 
        $limit: Int!,
        # $langcode: String!,
        $language: String!,
        $field_type: String!,
        $tag: String!
    ) {
        index: allNodeBlog (
            filter: {
              # this node is available in graphql database since it is published, we need to filter it out here
              title: {
                  ne: "SOFT4NET_DO_NOT_DELETE"
              }
              langcode: {
                # eq: $langcode # @todo: bad value!!!
                eq: $language # this variable is set by gatsby-plugin-intl
              }
              field_type: {
                eq: $field_type
              }
              relationships: {
                field_tags: {
									elemMatch: {
                    name: {
                      eq: $tag
                    }
                  }
                }
              }
            }
            sort: {
              fields: [created]
              order: DESC
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }
    }
`