import React from "react"

// s4n
import PaginationStatic from './static'
import PaginationDynamic from './dynamic'



const ComponentStateless = (props) => {
    const { 
        pageContext, linkTranslation, // static pager
        onPageChange, pageCount, forcePage, // dynamic pager from appollo query
    } = props

    const isDynamic = onPageChange && pageCount && forcePage;

    return isDynamic ? 
        <PaginationDynamic onPageChange={onPageChange} pageCount={pageCount} forcePage={forcePage} /> 
        : 
        <PaginationStatic pageContext={pageContext} linkTranslation={linkTranslation} />
    ;
}

export default ComponentStateless